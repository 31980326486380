import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Header from "./Header"
import Grid from "@material-ui/core/Grid"
import FaqItem from "./FaqItem"
import AdsComponent from "./AdsComponent"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  section: {
    height: "100%",
    display: "flex",
    flexFlow: "column",
  },
  contentArea: {
    height: "100%",
  },
  grid: {
    padding: theme.spacing(3),
    textAlign: "center",
    marginTop: "48px",
    marginBottom: theme.spacing(1),
  },
  body: {
    fontFamily: 'Mastro Sans DEMO Regular, sans-serif',
  },
  header: {
    position: "fixed",
    width: "100%",
    zIndex: 1000
  },
})

class AboutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {
    window.gtag("event", "page_view", {
      page_path: "/about"
    })
  }

  toggleAnswer = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };

  render() {
    const { classes } = this.props
    const { isActive } = this.state;

    return (
      <section className={classes.section}>
        <Header className={classes.header} />

        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">About</Typography>
          </Grid>

          <Grid item xs={3}>
            <AdsComponent dataAdSlot='3646789906' />
          </Grid>

          <Grid item xs={6} style={{ marginBottom: '16px' }}>
            <FaqItem
              question='What is ERDPlus?'
              answer = 'ERDPlus is an online tool for creating Entity-Relationship Diagrams (ERDs), Relational Schemas, Star Schemas, and SQL DDL statements. ERDPlus allows you to visualize your database diagrams and create your databases quickly and efficiently.'
            ></FaqItem>
            <FaqItem
              question='Is ERDPlus free to use?'
              answer = 'Yes, ERDPlus is a free software plan that allows you to create and save your diagrams.'
            ></FaqItem>
            <FaqItem
              question='How do I get started with ERDPlus?'
              answer = 'To get started with ERDPlus, simply visit the website and sign up for a free account. Once registered, you can begin creating your database diagrams and schemas right away. You can also sign in with your Google account.'
            ></FaqItem>
            <FaqItem
              question='Can I export the images of my diagrams from ERDPlus?'
              answer = 'Yes, ERDPlus allows you to export images as PNG files using the Export Image option in the Menu tab when you create or open your diagram.'
            ></FaqItem>
            <FaqItem
              question='Is ERDPlus suitable for academic and professional use?'
              answer = 'Yes, ERDPlus is used by academics, students, and professionals in database development in over 180 countries across the world.'
            ></FaqItem>
            <FaqItem
              question='Do I need to install any software to use ERDPlus?'
              answer = 'No, ERDPlus is a web-based application, and you can access it through your web browser. There is no need to install any software on your computer.'
            ></FaqItem>
            <FaqItem
              question='What types of diagrams can I create with ERDPlus?'
              answer = 'ERDPlus supports the creation of Entity-Relationship Diagrams (ERDs), Relational Schemas, Star Schemas, and SQL DDL statements.'
            ></FaqItem>
          </Grid>
          <Grid item xs={3}>
            <AdsComponent dataAdSlot='2450926600' />
          </Grid>
        </Grid>
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AboutPage)
)
