import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from '../lib/Api';
import SqlQueriesActions, { SqlTypes } from '../redux/SqlQueries';
import GlobalActions from '../redux/Global';

function* executeSql(action) {
  try {
    yield put(GlobalActions.setBusy());
    const res = yield call(Api.postSqlQuery, action.sql);
    const executeSqlData = res.data;
    yield put(SqlQueriesActions.executeSqlSuccess(executeSqlData));
  } catch (e) {
    yield put(GlobalActions.showToast('Error', e));
  } finally {
    yield put(GlobalActions.clearBusy());
  }
}

export function* executeSqlSaga() {
  yield takeEvery(SqlTypes.EXECUTE_SQL, executeSql);
}
