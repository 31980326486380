const dev = {
  apiUrl: 'https://sjfw52mrt0.execute-api.us-east-1.amazonaws.com/dev/',
  apiUrl2:
    'https://i9fxmmgh6l.execute-api.us-east-1.amazonaws.com/api/query/legacy/',
  testing: 'https://66ef43483ed5bb4d0bf32311.mockapi.io/api/',
};

const production = {
  apiUrl: 'https://delcgtquxh.execute-api.us-east-1.amazonaws.com/prod/',
  apiUrl2:
    'https://i9fxmmgh6l.execute-api.us-east-1.amazonaws.com/api/query/legacy/',
};

const config = process.env.REACT_APP_STAGE === 'production' ? production : dev;

export default {
  ...config,
};
