import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import SqlActions from '../redux/SqlQueries';

const styles = (theme) => ({
  dialog: {
    margin: '30px',
  },
  input: {
    width: 300,
  },
});

class SchemaPreviewDialog extends Component {
  handleCancel = () => {
    this.props.schemaPreviewCancel();
  };

  render() {
    const { schema, showPreview, classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={showPreview}
        aria-labelledby="preview-schema-dialog"
        onEscapeKeyDown={this.handleCancel}
        className={classes.dialog}
      >
        <DialogTitle id="preview-schema-dialog">{`Schema preview: ${schema?.toUpperCase()}`}</DialogTitle>
        <DialogContent>
          {/* <AdsComponent dataAdSlot="4618413268" /> */}
          {schema === 'zagi' && (
            <img alt="Schema of ZAGI Second Edition" src="ZAGI.png" />
          )}
          {schema === 'hafh' && (
            <img alt="Schema of HAFH Second Edition" src="HAFH.png" />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel (ESC)
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schema: state.sqlQueries.schema,
    showPreview: state.sqlQueries.showPreview,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    schemaPreviewCancel: () => dispatch(SqlActions.schemaPreviewCancel()),
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withMobileDialog()(SchemaPreviewDialog))
);
