import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import withMobileDialog from "@material-ui/core/withMobileDialog"
import FolderActions from "../redux/Folder"

const styles = theme => ({
  dialog: {},
  input: {
    width: 300
  }
})

class ConfirmEmptyTrashDialog extends Component {
  handleCancel = () => {
    this.props.emptyTrashCancel()
  }

  handleSubmit = () => {
    this.props.emptyTrash()
  }

  render() {
    const { showEmptyTrash, classes } = this.props

    return (
      <Dialog
        open={showEmptyTrash}
        aria-labelledby="confirm-empty-trash-dialog"
        onEscapeKeyDown={this.handleCancel}
        className={classes.dialog}>
        <DialogTitle id="confirm-empty-trash-dialog">Empty Trash</DialogTitle>
        <DialogContent>
          <DialogContentText>Permanently delete all documents in the Trash folder?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel (ESC)
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Empty Trash
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    showEmptyTrash: state.folder.showEmptyTrash
  }
}

const mapDispatchToProps = dispatch => {
  return {
    emptyTrashCancel: () => dispatch(FolderActions.emptyTrashCancel()),
    emptyTrash: () => dispatch(FolderActions.emptyTrash())
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withMobileDialog()(ConfirmEmptyTrashDialog))
)
