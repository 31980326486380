import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  executeSql: ['sql'],
  executeSqlSuccess: ['executeSqlData'],
  setSqlText: ['sql'],
  setSchema: ['schema'],
  setDisableButton: ['disableButton'],
  schemaPreviewShow: null,
  schemaPreviewCancel: null,
});

const INITIAL_STATE = {
  sql: null,
  schema: null,
  executeSqlData: null,
  disableButton: false,
};

const executeSql = (state) => {
  return state;
};

const executeSqlSuccess = (state, action) => {
  return {
    ...state,
    executeSqlData: action.executeSqlData,
  };
};

const setSqlText = (state, action) => {
  return {
    ...state,
    sql: action.sql,
  };
};

const setSchema = (state, action) => {
  return {
    ...state,
    schema: action.schema,
  };
};

const setDisableButton = (state, action) => {
  return {
    ...state,
    disableButton: action.disableButton,
  };
};

const schemaPreviewShow = (state) => ({
  ...state,
  showPreview: true,
});

const schemaPreviewCancel = (state) => ({
  ...state,
  showPreview: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.EXECUTE_SQL]: executeSql,
  [Types.EXECUTE_SQL_SUCCESS]: executeSqlSuccess,
  [Types.SET_SQL_TEXT]: setSqlText,
  [Types.SET_SCHEMA]: setSchema,
  [Types.SET_DISABLE_BUTTON]: setDisableButton,
  [Types.SCHEMA_PREVIEW_SHOW]: schemaPreviewShow,
  [Types.SCHEMA_PREVIEW_CANCEL]: schemaPreviewCancel,
});

export const SqlTypes = Types;

export default Creators;
